<template>
  <main
    :class="{ 'import-list-section-edit': showImportListCardV2 }"
    class="import-list-section"
    :key="componentKey"
  >
    <div v-if="userType == 'retailer'">
      <retailerNavigation v-if="!$route.path.includes('/sh-retailer')" />

      <v-main>
        <div
          :class="{ 'retailer-main-container-import-list': !isShopify }"
          class="page-wrapper"
          style="position: relative; padding: 20px; padding-top: 0"
          :style="{
            height: isShopify && showImportListCardV2 ? '200vh' : '',
            background: isShopify && showImportListCardV2 ? '#fff' : '0',
          }"
        >
          <div :class="{ 'mt-10': !isShopify }" class="query_container">
            <h1 style="font-weight: bold; font-size: 20px">Import List</h1>
          </div>

          <ImportListCardV2
            v-if="showImportListCardV2"
            :product="selectedProduct"
            :key="selectedProduct.id"
            :counterOfIndex="counterOfIndex"
            :countOfProduct="countOfProduct"
            ref="ImportListCardV2"
            @previous-edit-product="previousEditProduct"
            @next-edit-product="nextEditProduct"
            @force-render="ForceRerender"
            @productremoved="ProductRemoved($event)"
            @forcereload="
              FetchImportedList({
                count,
                offset: 0,
                seller,
              }),
                ForceRerender()
            "
            @close="handleEditProductClose"
          />

          <div v-if="!allowToPushAll" class="bulk-container">
            <button
              class="vgang_btn vgang_btn_left"
              @click="PushAllToStore(products)"
              style="color: #fff; border-radius: 8px"
            >
              Push selected items
            </button>

            <button
              class="vgang_btn"
              @click="RemoveAllProduct()"
              style="color: #fff; border-radius: 8px"
            >
              Remove selected items
            </button>
          </div>

          <ItemsTable
            v-if="!showImportListCardV2"
            :products="products"
            :search-query="searchQuery"
            @search="handleSearch"
            @productselected="ProductSelected($event)"
            @selectAllProduct="selectAllProduct($event)"
            @edit-product="handleEditProduct"
            @pushToStoreSingleProduct="pushToStore($event)"
            @remove-product="removeProduct($event)"
          />
        </div>
      </v-main>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="no-access">You don't have access to this panel</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
const ImportListCardV2 = () => import("@/components/ImportListCardV2.vue");
import ItemsTable from "@/components/ItemsTable.vue";
import retailerNavigation from "@/components/navigation/retailer.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ImportList",
  components: {
    ImportListCardV2,
    ItemsTable,
    retailerNavigation,
  },
  data() {
    return {
      userType: "retailer",
      count: 100,
      page: 1,
      page_size: 0,
      currentSort: "newest",
      seller: "",
      isShopify: window.self !== window.top ? true : false,
      allProductSelected: false,
      failedToPuch: false,
      listOfSelectedProducts: [],
      selectedProduct: {},
      loading: true,
      showSortBy: false,
      sortBy: "Newest",
      sort_by_arrow: false,
      componentKey: 0,
      searchQuery: "",
      showImportListCardV2: false,

      counterOfIndex: null,
    };
  },

  async created() {
    let pag = {
      count: this.count,
      offset: this.count * (this.page - 1),
      sort: this.sortBy.toLowerCase(),
      seller: this.seller,
    };
    await this.FetchImportedList(pag);
  },
  metaInfo: {
    // Set the meta description
    meta: [
      {
        name: "vang, Import List",
        content: "Import List",
      },
    ],
  },
  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
      products: "retailer/importedProductList",
      totalProductCount: "retailer/importedProductListTotalCount",
      listOfSellers: "retailer/listOfSellers",
    }),
    countOfProduct() {
      return this.products.length;
    },
    allowToPushAll() {
      return this.listOfSelectedProducts.length > 0 ? false : true;
    },
  },
  methods: {
    ...mapActions(["alert/newAlert", "alert/clear"]),
    previousEditProduct() {
      this.counterOfIndex = this.products.indexOf(this.selectedProduct);
      if (this.counterOfIndex > 0) {
        this.counterOfIndex--;
      }
      if (this.counterOfIndex === 0) this.counterOfIndex = 0;

      this.counterOfIndex === 0
        ? (this.isFirstProduct = true)
        : (this.isFirstProduct = false);
      this.selectedProduct = this.products[this.counterOfIndex];
    },
    nextEditProduct() {
      this.counterOfIndex = this.products.indexOf(this.selectedProduct);
      this.counterOfIndex++;
      let lastIndex = this.products.length;
      this.counterOfIndex + 1 === lastIndex
        ? (this.isLastProduct = true)
        : (this.isLastProduct = false);

      this.selectedProduct = this.products[this.counterOfIndex];
    },
    ...mapActions([
      "retailer/getImportedList",
      "retailer/getListOfSellers",
      "retailer/submitImportedList",
      "retailer/removeImportedList",
      "alert/clear",
    ]),
    handleEditProduct(product) {
      // Set the showImportListCardV2 to true to display the component
      this.selectedProduct = product;

      this.showImportListCardV2 = true;
    },

    handleEditProductClose() {
      this.showImportListCardV2 = false;
    },
    async FetchImportedList(pag) {
      console.log("FETCHED");

      try {
        await this["retailer/getImportedList"](pag);
        this.page_size = Math.ceil(this.totalProductCount / this.count);
        this.loading = false;
      } catch (e) {
        this["alert/newAlert"]({
          type: "error",
          message: e.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
      }
    },
    async SortBySellers(event) {
      this.seller = String(event.multiSelectedValue);
      this.Search();
    },

    async Search() {
      let pag = {
        count: this.count,
        offset: 0,
        search: this.searchQuery,
        //seller: this.seller,
      };
      try {
        await this["retailer/getImportedList"](pag);
        this.page_size = Math.ceil(this.totalProductCount / this.count);
      } catch (error) {
        console.log(error);
      }
    },
    handleSearch(query) {
      this.searchQuery = query;
      this.Search();
    },

    removeText() {
      this.searchQuery = "";
    },
    async SortByValue(event) {
      this.currentSort = event.sort_by;
      let pag = {
        count: this.count,
        offset: 0,
        sort: this.currentSort.toLowerCase(),
        seller: this.seller,
      };
      try {
        await this["retailer/getImportedList"](pag);
        this.page_size = Math.ceil(this.totalProductCount / this.count);
        this.showSortBy = false;
      } catch (error) {
        console.log(error);
      }
    },
    ProductSelected(event) {
      const found = this.listOfSelectedProducts.find(
        (product) => product.id === event.id
      );

      found
        ? this.listOfSelectedProducts.splice(
            this.listOfSelectedProducts.indexOf(found),
            1
          )
        : this.listOfSelectedProducts.push(event);
    },
    async pushToStore(productID) {
      this.pushingToStore = true;
      this.disablePushToStore = true;
      this.selectedProduct = false;
      const pushed = await this["retailer/submitImportedList"](productID);
      if (pushed) {
        this.SuccessMessage = true;
        this.disableSaveChange = true;
        this.FetchImportedList();
        this.ForceRerender();

        setTimeout(() => {
          this.pushingToStore = false;
          this.removingProduct = false;
        }, 1000);
      } else {
        this.ErrorMessage = true;
        this.disablePushToStore = false;
        this.selectedProduct = true;

        setTimeout(() => {
          this.pushingToStore = false;
        }, 1000);
      }
    },
    async PushAllToStore(products) {
      this.listOfSelectedProducts.forEach((product) => {
        this.pushToStore(product.id);
      });
      this.listOfSelectedProducts = [];
    },
    async RemoveAllProduct() {
      const confirmRemove = window.confirm(
        `Unsaved change. Are you sure you want to delete this products?`
      );
      if (confirmRemove) {
        this.listOfSelectedProducts.forEach((child) => {
          this.removeProductForAll(child);
        });
        this.listOfSelectedProducts = [];
      }
    },
    async removeProductForAll(product) {
      this.removingProduct = true;
      const deleted = await this["retailer/removeImportedList"](product?.id);

      if (deleted) {
        this.SuccessMessage = true;
        this.disableSaveChange = true;
        this.FetchImportedList();
        this.ForceRerender();

        setTimeout(() => {
          this.removingProduct = false;
        }, 1000);
      } else {
        setTimeout(() => {
          this.removingProduct = false;
        }, 1000);
        this.ErrorMessage = true;
        this.disablePushToStore = false;
      }
    },
    async removeProduct(product) {
      const confirmRemove = window.confirm(
        `Unsaved change. Are you sure you want to delete this product?\nProduct Name: ${product?.title}`
      );
      if (confirmRemove) {
        this.removingProduct = true;
        const deleted = await this["retailer/removeImportedList"](product?.id);

        if (deleted) {
          this.SuccessMessage = true;
          this.disableSaveChange = true;
          this.FetchImportedList();
          this.ForceRerender();

          setTimeout(() => {
            this.removingProduct = false;
          }, 1000);
        } else {
          setTimeout(() => {
            this.removingProduct = false;
          }, 1000);
          this.ErrorMessage = true;
          this.disablePushToStore = false;
        }
      }
    },

    selectAllProduct(checked) {
      this.listOfSelectedProducts = checked;
    },
    async Next(page) {
      console.log(page);
      let pag = {
        count: this.count,
        offset: this.count * (this.page - 1),
        sort: this.currentSort,
        seller: this.seller,
      };
      try {
        await this["retailer/getImportedList"](pag);
        this.page_size = Math.ceil(this.totalProductCount / this.count);
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      }
    },
    ForceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables2.scss";
.bulk-container {
  position: fixed;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  width: 450px;
  left: calc(50% - 225px);
  background: #202123;
  height: 70px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.85) 0px 4px 4px 0px;
  .vgang_btn_left {
    margin-right: 20px;
    @include sm {
      margin-right: 12px;
    }
  }
  .vgang_btn {
    @include md {
      font-size: 12px;
    }
  }
  @include md {
    height: 60px;
    width: 60vw;
    left: calc(50% - 144px);
    bottom: 70px;
  }
  @include sm {
    width: 77vw;
    left: calc(50% - 130px);
    bottom: 70px;
  }
  @include breakpoint(414px) {
    width: 75vw;
    left: calc(50% - 105px);
  }
}
.import-list-section-edit {
  .v-main__wrap {
    background: #fff;
  }
  .import_list_card {
    box-shadow: unset;
  }
}
.import-list-section {
  .close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 15px;
  }
  .empty-products-container {
    padding: 89px;
    margin-top: 21px;
    @include md {
      padding: 34px;
    }
    @include sm {
      padding: 21px;
    }
    .chair-image {
      height: 172px;
      width: 148px;
    }
    .Explore-btn {
      font-size: 12px;
    }
    .header {
      color: #202223;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      border-bottom: unset !important;
      @include sm {
        font-size: 16px;
      }
    }
    .description {
      color: #6d7175;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .importlist_banner {
    margin-top: 16px;

    padding-right: 100px;
    width: 100%;
  }

  .retailer-main-container-import-list {
    @media screen and (min-width: 1621px) {
      width: calc(100% - 256px) !important;
      margin-left: auto;
    }

    @media screen and (min-width: 300px) {
      width: calc(100% - 72px);
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
