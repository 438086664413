<template>
  <div class="import_list_card">
    <div class="input_search_parent">
      <span class="material-icons search_icon" @click.stop="Search">
        search
      </span>
      <input
        class="input_search"
        :maxWidth="'700px'"
        placeholder="Find products"
        v-model="searchQuery"
        @keyup="handleSearch"
      />

      <span class="sort_minor" @click="handleSort('title')">
        <img
          alt="sort"
          loading="lazy"
          src="@/assets/img/sort_minor.png"
          style="cursor: pointer"
          width="20px"
          height="20px"
        />
      </span>
    </div>
    <div class="tablefilters">
      <div
        v-for="filter in filterOptions"
        :key="filter.value"
        @click="toggleFilter(filter.value)"
        :class="{ active: isSelectedFilter(filter.value) }"
        class="filter-option"
      >
        {{ filter.label }}
      </div>
    </div>
    <div class="table-container" v-if="paginatedProducts.length > 0">
      <table
        style="background: rgb(255, 255, 255); border-radius: 8px"
        class="Polaris-DataTable__Table"
      >
        <thead style="background: #fafbfb">
          <tr>
            <th
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
              style="width: 50px"
            >
              <label class="custom-checkbox-2">
                <input
                  type="checkbox"
                  @change="selectAllProducts"
                  v-model="selectAll"
                />
                <span class="checkmark-2" style="top: -9px !important"></span>
              </label>
            </th>

            <th
              data-polaris-header-cell="true"
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
              scope="col"
              @click="handleSort('title')"
            >
              Product
              <span class="sort-indicator">
                <img
                  alt="sort"
                  loading="lazy"
                  src="@/assets/img/Primary fill.png"
                  style="cursor: pointer"
                  width="6px"
                  height="10px"
                />
              </span>
            </th>
            <th
              data-polaris-header-cell="true"
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
              scope="col"
              @click="handleSort('stocks')"
            >
              Inventory
              <span class="sort-indicator">
                <img
                  loading="lazy"
                  src="@/assets/img/Primary fill.png"
                  style="cursor: pointer"
                  width="6px"
                  height="10px"
                  alt="Sort"
                />
              </span>
            </th>
            <th
              data-polaris-header-cell="true"
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header"
              scope="col"
            >
              Supplier
            </th>
            <th
              data-polaris-header-cell="true"
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header"
              scope="col"
              style="text-align: right"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable each-row" v-for="i in filteredProducts" :key="i.id" :class="i.hasBeenPublished ? 'deactive' : ''"> -->
          <tr
            class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable each-row"
            v-for="i in paginatedProducts"
            :key="i.id"
            :class="i.hasBeenPublished ? 'deactive' : ''"
          >
            <th
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
              scope="row"
            >
              <label class="custom-checkbox-2">
                <input
                  ref="productCheckBox"
                  type="checkbox"
                  :value="i"
                  @change="selectProductFunction(i)"
                />

                <span class="checkmark-2" style="top: -9px !important"></span>
              </label>
            </th>
            <th
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell"
              scope="row"
              style="display: flex; align-items: center"
            >
              <img
                loading="lazy"
                :src="`${i?.images[0]?.ThumbnailImage}`"
                :alt="i.title"
                style="
                  width: 50px;
                  height: 50px;
                  border-radius: 5px;
                  margin-right: 10px;
                "
              />
              {{ i.title.length > 60 ? i.title.slice(0, 60) + "..." : i.title }}
            </th>
            <td
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable--hoverable Polaris-DataTable__Cell"
            >
              <div>
                {{ calculateTotalStock(i.variants) }} in stock for
                {{ i.variants.length }} variants
              </div>
            </td>
            <td
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell"
            >
              <router-link
                :to="'./supplierProfile/' + i.seller.id"
                style="
                  color: #008060 !important;
                  text-decoration: underline;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                {{ i.seller.BrandName }}
              </router-link>
            </td>
            <td
              class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell"
            >
              <div
                @click="pushToStoreSingleProduct(i.id)"
                class="tableEdtiBtn d-inline-block"
              >
                <img
                  width="18px"
                  height="18px"
                  loading="lazy"
                  src="@/assets/img/shop-icon.png"
                />
                <span class="tooltiptext">Push to store</span>
              </div>
              <div @click="editProduct(i)" class="tableEdtiBtn d-inline-block">
                <img
                  width="18px"
                  height="18px"
                  loading="lazy"
                  src="@/assets/img/edit.png"
                />
                <span class="tooltiptext">Edit product</span>
              </div>

              <div
                @click="$emit('remove-product', i)"
                class="tableEdtiBtn d-inline-block"
              >
                <img
                  width="18px"
                  height="18px"
                  loading="lazy"
                  src="@/assets/img/trash.png"
                />
                <span class="tooltiptext">Delete product</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="empty-products-container">
      <div
        class="container row-gap-3 d-flex justify-content-center flex-column align-items-center"
      >
        <img
          class="chair-image"
          src="@/assets/img/importListBg.png"
          loading="lazy"
          alt="import list is empty"
        />
        <h4 class="header">Your import list is empty!</h4>
        <p class="description">
          Head over to the find product page to start adding products to your
          import list.
        </p>
        <router-link :to="'./list-of-products?host=' + host">
          <PButton class="Explore-btn" primary>
            Explore Products to Sell
          </PButton>
        </router-link>
      </div>
    </div>
    <!-- Pagination controls -->
    <div v-if="totalPages > 1" class="row mt-2">
      <div
        class="col-sm-12 col-md-12 col-12"
        style="display: flex; justify-content: center"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          style="width: auto; display: flex"
        >
          <ul class="v-pagination theme--light">
            <li>
              <button
                :disabled="currentPage === 1"
                type="button"
                aria-label="Previous page"
                class="v-pagination__navigation v-pagination__navigation--disabled"
                @click="changePage(currentPage - 1)"
              >
                <span
                  class="material-symbols-outlined v-icon notranslate mdi mdi-chevron-left theme--light"
                >
                  chevron_left
                </span>
              </button>
            </li>

            <li v-for="page in totalPages" :key="page">
              <button
                :type="currentPage === page ? 'button' : 'button'"
                :aria-label="
                  currentPage === page
                    ? 'Current Page, Page ' + page
                    : 'Goto Page ' + page
                "
                :class="[
                  'v-pagination__item',
                  { 'v-pagination__item--active': currentPage === page },
                ]"
                @click="changePage(page)"
                :style="
                  currentPage === page
                    ? {
                        'background-color': 'rgb(0, 143, 74)',
                        'border-color': 'rgb(0, 143, 74)',
                        color: '#fff',
                      }
                    : {}
                "
                class="v-pagination__item"
              >
                {{ page }}
              </button>
            </li>

            <li>
              <button
                :disabled="currentPage === totalPages"
                type="button"
                aria-label="Next page"
                class="v-pagination__navigation"
                @click="changePage(currentPage + 1)"
              >
                <span
                  class="v-icon notranslate mdi mdi-chevron-right theme--light material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isShopifyEmbedded } from "@shopify/app-bridge/utilities";
export default {
  name: "ItemsTable",
  props: {
    products: { type: Array, required: true },
    products: Array, // Existing prop for products
  },

  data: () => ({
    selectedProducts: [], // Array to store selected products
    selectAll: false, // Add this property for the "Select All" checkbox
    host: null,
    sortColumn: "title", // Default sorting column
    sortOrder: "asc", // Default sorting order ('asc' or 'desc')
    searchQuery: "",
    selectedFilters: ["all"], // Initial selected filters (default: "All")
    filterOptions: [
      { label: "All", value: "all" },
      { label: "Low Stock", value: "lowStock" },
      { label: "Out of Stock", value: "outOfStock" },
      { label: "Inactive", value: "inactive" },
    ],
    currentPage: 1, // Current page number
    itemsPerPage: 24, // Number of items to display per page
  }),

  computed: {
    paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      return this.filteredProducts.slice(startIndex, endIndex);
    },
    filteredProducts() {
      // First, create a copy of the products array to avoid modifying the original array
      const sortedProducts = [...this.products];

      // Sort the copied array based on the selected sorting column and order
      sortedProducts.sort((a, b) => {
        if (this.sortColumn === "stocks") {
          // Sort by the number of stocks if the sorting column is 'stocks'
          const aStock = this.calculateTotalStock(a.variants);
          const bStock = this.calculateTotalStock(b.variants);

          if (this.sortStocksBy === "asc") {
            return aStock - bStock;
          } else {
            return bStock - aStock;
          }
        } else {
          // Sort by other columns
          let aValue = a[this.sortColumn];
          let bValue = b[this.sortColumn];

          if (this.sortOrder === "asc") {
            return aValue?.localeCompare(bValue);
          } else {
            return bValue?.localeCompare(aValue);
          }
        }
      });

      // Apply filters to the sorted array
      const filteredList = sortedProducts.filter((product) => {
        const totalStock = this.calculateTotalStock(product.variants);
        return (
          this.selectedFilters.includes("all") ||
          (this.selectedFilters.includes("lowStock") &&
            totalStock > 0 &&
            totalStock < 10) ||
          (this.selectedFilters.includes("outOfStock") && totalStock === 0) ||
          (this.selectedFilters.includes("inactive") && product.inactive)
        );
      });

      // Return the filtered and sorted list
      return filteredList;
    },

    totalPages() {
      // Calculate the total number of pages based on the number of filtered products
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
    // Calculate the start index of the current page
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    // Calculate the end index of the current page
    endIndex() {
      return this.currentPage * this.itemsPerPage;
    },
    // Get the products to display on the current page
    paginatedProducts() {
      return this.filteredProducts.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    ...mapActions([
      "retailer/updateImportedList",
      "retailer/submitImportedList",
      "retailer/removeImportedList",

      "alert/clear",
    ]),

    selectProductFunction(product) {
      this.$emit("productselected", product);
    },

    editProduct(product) {
      // Emit a custom event to send the product to the parent component

      this.$emit("edit-product", product);
    },

    pushToStoreSingleProduct(id) {
      // Emit a custom event to send the product to the parent component

      this.$emit("pushToStoreSingleProduct", id);
    },

    checkAllProduct(checked) {
      const allCheckBox = this.$refs.productCheckBox;
      allCheckBox.forEach((item) => (item.checked = checked));
    },

    selectAllProducts() {
      if (this.selectAll) {
        // If the "Select All" checkbox is checked, add all products to selectedProducts
        this.selectedProducts = [...this.products];
        this.$emit("selectAllProduct", [...this.products]);
        this.checkAllProduct(true);
      } else {
        // If the "Select All" checkbox is unchecked, clear the selectedProducts array
        this.selectedProducts = [];
        this.$emit("selectAllProduct", []);
        this.checkAllProduct(false);
      }

      this.selected = this.selectAll
        ? this.products.map((product) => product.id)
        : [];
    },

    changePage(page) {
      this.currentPage = page;
      if (this.selectAll) {
        setTimeout(() => {
          this.checkAllProduct(true);
        }, 10);
      }
    },
    handleSort(column) {
      if (this.sortColumn === column) {
        // If the same column is clicked again, reverse the sorting order
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        // If a different column is clicked, set it as the new sorting column and default to ascending order
        this.sortColumn = column;
        this.sortOrder = "asc";
      }

      // Check if the sorting column is 'stocks' and update the sortStocksBy property
      if (column === "stocks") {
        this.sortStocksBy = this.sortOrder;
      } else {
        // Reset sortStocksBy to 'none' for other columns
        this.sortStocksBy = "none";
      }

      // Call a method to re-sort your products based on the selected sorting column and order
      this.sortProducts();
    },

    toggleFilter(filter) {
      if (this.selectedFilters.includes(filter)) {
        // If the filter is already selected, deselect it
        this.selectedFilters = ["all"];
      } else {
        // Deselect all filters and select the clicked filter
        this.selectedFilters = [filter];
      }
    },
    isSelectedFilter(filter) {
      return this.selectedFilters.includes(filter);
    },
    // Existing methods

    handleSearch() {
      this.$emit("search", this.searchQuery);
    },
    calculateTotalStock(variants) {
      let totalStock = 0;
      if (variants) {
        for (const variant of variants) {
          totalStock += variant.stock;
        }
      }
      return totalStock;
    },
  },
  ForceRerender() {
    this.componentKey += 1;
  },
  mounted() {
    if (isShopifyEmbedded()) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      this.host = url.searchParams.get("host");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.table-container {
  overflow: unset !important;
  background: rgb(255, 255, 255);
  border: 1px solid #dcdcdc;
  @include lg {
    overflow-x: auto !important;
    margin-top: 70px;
  }
}

.mport_list_card {
  padding: 32px !important;
}
.sort_minor {
  border: 1px solid #babfc3;
  box-shadow: 0px 1px 0px 0px #0000000d;
  padding: 7px;
  margin-left: 10px;
  border-radius: 3px;
}

.tableEdtiBtn {
  position: relative;
  cursor: pointer;
  border: none !important;
  padding: 5px !important;

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 12222222222222222222;
    bottom: 120%;
    left: 50%;
    margin-left: -60px;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}

.v-pagination {
  display: flex;
}

.v-pagination li {
  list-style: none;
  width: 30px;
  height: 30px;

  margin: 2px;
  justify-content: center;
  align-items: center;
}

.v-pagination li button {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #babfc3;
}

.v-pagination li span {
  display: block;

  text-align: center;
}

.tableEdtiBtn {
  cursor: pointer;
  border: none !important;
  padding: 5px !important;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.pagination button {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0073e6;
  color: white;
  border-color: #0073e6;
}

.tablefilters {
  display: flex;
  flex: 1;
  margin-bottom: 32px;
  @include sm {
    flex-wrap: wrap;
  }
}

.input_search_parent {
  position: absolute;
  right: 32px;
  top: 20px;
  @include lg {
    right: unset;
    left: 32px;
    top: 63px;
  }
  @include sm {
    right: unset;
    left: 12px;
    top: 90px;
  }
}

.input_search {
  border: 1px solid #babfc3;
  padding: 5px;
  border-radius: 4px;
  padding-left: 25px;
  height: 35px;
  @include lg {
    width: 306px;
  }
  @include breakpoint(520px) {
    width: 80%;
  }
}

.search_icon {
  position: absolute;
  padding-top: 6px;
  padding-left: 3px;

  color: #475467;
  opacity: 0.4;
  user-select: none;

  // min-width: 40px;
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.filter-option {
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.tablefilters .active {
  background: #b1f7d8;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) {
  padding: 16px 0 !important;
}
</style>
